import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

import { GuestFields, updateGuest } from "../../../store/slices/guestsSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { setPricing } from "../../../store/slices/pricingSlice";
import { showModal } from "../../../store/slices/modalSlice";
import MODAL from "../../../utils/constants/modal";

import LoadingContainer from "../../containers/LoadingContainer";
import BookingDetails from "../../base/BookingDetails";
import SubmitButton from "./molecules/SubmitButton";
import RoomForms from "./molecules/RoomForms";
import Button from "../../shared/Button";

import styles from "./index.module.scss";

type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: Partial<GuestFields>;
      expand: () => void;
      setErrors: (errors: Partial<Record<keyof GuestFields, string>>) => void;
      scrollIntoView: () => void;
    }
  >
>;

type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

function Passengers() {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const { check_email_uniqueness } = useTypedSelector(
    (state) => state.environment,
  );

  const submitButtonRef: MutableRefObject<{
    submit: (guests: SubmitButtonPayload) => void;
  }> = useRef({
    submit: () => null,
  });

  const roomsFormsRefs: Record<
    number,
    Record<
      number,
      MutableRefObject<{
        expand: () => void;
        scrollIntoView: () => void;
        validateForms: () => {
          inputs: GuestFields;
          errors: GuestFields | null;
        };
      } | null>
    >
  > = {};

  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Function to validate all room forms and compile their outputs.
  const flattenForms = () => {
    let validationResult: ValidateFormsOutput = {};

    for (const roomNumber in roomsFormsRefs) {
      const room = roomsFormsRefs[+roomNumber];

      for (const formNumber in room) {
        const roomForms = room[+formNumber];
        const roomFormsValidationResult = roomForms.current?.validateForms();

        validationResult = {
          ...validationResult,
          ...(roomFormsValidationResult ?? {}),
        };
      }
    }

    if (check_email_uniqueness) {
      const emails: string[] = [];

      for (const roomNumber in validationResult) {
        const room = validationResult[+roomNumber];

        for (const guestKey in room) {
          const { inputs, errors, setErrors } =
            validationResult[+roomNumber][guestKey];

          const isEmailExistsAndValid = !errors?.email && inputs.email;
          const isEmailUnique = !emails.includes(inputs.email);

          if (isEmailExistsAndValid && !isEmailUnique) {
            setErrors({ email: t("Email should be unique") });

            if (!errors) {
              validationResult[+roomNumber][guestKey].errors = {};
            }

            validationResult[roomNumber][guestKey].errors.email = t(
              "Email should be unique",
            );
          }

          emails.push(inputs.email);
        }
      }
    }

    return validationResult;
  };

  // Handler for the form validation process.
  const handleValidate = () => {
    const validationResult = flattenForms();

    let isFormsValid = true;

    for (const roomNumber in validationResult) {
      const room = validationResult[+roomNumber];

      for (const formKey in room) {
        const form = room[+formKey];
        const isFormValid = !form.errors;

        if (!isFormValid && isFormsValid) {
          requestAnimationFrame(() => {
            form.expand();

            setTimeout(() => {
              form.scrollIntoView();
            }, 500);
          });

          isFormsValid = false;
        }

        if (isFormValid) {
          dispatch(
            updateGuest({
              stateroomNumber: +roomNumber,
              guestNumber: +formKey,
              guestFields: form.inputs,
            }),
          );
        }
      }
    }

    if (isFormsValid) {
      submitButtonRef.current.submit(validationResult);
    }
  };

  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  // Effect hooks to set pricing information on mount and adjust scroll position.
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading || !rooms}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("who’s travelling")}</h1>

            <div className={styles.passengers}>
              {Object.entries(rooms ?? {}).map(
                ([stateroomKey, stateroom], stateroomIndex) => {
                  const ref = useRef(null);

                  if (!roomsFormsRefs[+stateroomKey]) {
                    roomsFormsRefs[+stateroomKey] = {};
                  }

                  roomsFormsRefs[+stateroomKey][stateroomIndex + 1] = ref;

                  return (
                    <div key={nanoid()} className={styles.passenger}>
                      <div className={styles.stateroomInfo}>
                        <p className={styles.subtitle}>
                          {t("stateroom")} {stateroomIndex + 1}
                        </p>

                        <p className={styles.name}>
                          {stateroom?.grade?.name} -{" "}
                          <span>
                            {stateroom?.grade?.descriptions?.[0].description}
                          </span>
                        </p>

                        <p className={styles.name}>
                          {stateroom?.guestsNumber} {t("guests")}
                        </p>
                        <p className={styles.name}>
                          {t("stateroom")} {stateroom?.cabin?.number}
                        </p>
                      </div>

                      <RoomForms
                        ref={ref}
                        isIncludesLead={stateroomIndex === 0}
                        roomNumber={+stateroomKey}
                        guestsAmount={stateroom?.guestsNumber ?? 1}
                      />
                    </div>
                  );
                },
              )}
            </div>

            <SubmitButton ref={submitButtonRef} onClick={handleValidate} />
          </div>

          <div className={styles.right}>
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="FiPlus"
              onClick={handleShowItineraryModal}
            />

            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Passengers;
